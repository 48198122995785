import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { UserAuthService } from '../services/auth/user-auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private auth: UserAuthService) {}

    canActivate() {
        if (localStorage.getItem('isLoggedin')) {
            const user = this.auth.getStoredUser();
            if (!user.is_completed) {
                this.router.navigate(['edit-organization', user.userorganizations[0].organization_id]);
            } else {
                return true;
            }
        }

        this.router.navigate(['/login']);
        return false;
    }
}
