import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserAuthService } from '../auth/user-auth.service';
import { ToastrService } from 'ngx-toastr';
import { tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';

import * as utf8 from 'crypto-js/enc-utf8';
import * as AES from 'crypto-js/aes';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AdminAuthService } from '../auth/admin-auth.service';

@Injectable()
export class CommonHttpClient {
    private httpOptions;
    private httpOptionsforVideo;
    private logsReqResData = true; // Make it True For Debugging purpose only
    private _baseApiUrl = environment.baseApiUrl;

    constructor(private http: HttpClient,
                private userAuthService: UserAuthService ,
                private adminAuthService: AdminAuthService,
                private toastr: ToastrService,
                private router: Router ,
                private ngxService: NgxUiLoaderService) {}

    private _configHeaders = (isAdmin) => {
        let headers = new HttpHeaders();
        // headers = headers.set('Content-Type', 'application/json');
        if (isAdmin) {
            if (this.adminAuthService.getStoredUser() && this.adminAuthService.user.token) {
                headers = headers.set('Authorization', this.adminAuthService.user.token);
            }
        } else {
            if (this.userAuthService.getStoredUser() && this.userAuthService.user.token) {
                headers = headers.set('Authorization', this.userAuthService.user.token);
            }
        }
        this.httpOptionsforVideo = { reportProgress: true, observe: 'events', headers };
        this.httpOptions = { headers };
    }

    get(url, showToastr = false , isAdmin = false) {
        this._configHeaders(isAdmin);
        return this.http.get(this._baseApiUrl + url, this.httpOptions).pipe(
            tap((resp: any) => {
                if (resp.status === 'success' && showToastr) {
                    this.showToastr('success', resp.message, '');
                } else if (resp.status === 'failed'  && showToastr) {
                    this.showToastr('error', resp.message, '');
                }
            }),
            map((resp: any) => {
                if (resp.data) {
                    resp.data = this.decrypt(resp.data);
                    // With Decryption
                    // Without Decryption
                    // resp.data = resp.data;
                }
                this.consoleLogData({ method: 'GET', url, resp });
                return resp;
            }),
            catchError(err => {
                this.handleError(err, showToastr , isAdmin);
                this.consoleLogData(err);
                return throwError(err);
            })
        );
    }

    post(url, data, showToastr = false , isAdmin = false) {
        this._configHeaders(isAdmin);
        // With Ecryption
        return this.http.post(this._baseApiUrl + url, data , this.httpOptions).pipe(
            // Without Ecryption
            // return this.http.post(this._baseApiUrl + url,  data , this.httpOptions).pipe(
            tap((resp: any) => {
                if (resp.status === 'success' && showToastr) {
                    this.showToastr('success', resp.message, '');
                } else if (resp.status === 'failed' && showToastr) {
                    this.showToastr('error', resp.message, '');
                }
                this.consoleLogData({ method: 'POST', url, data, resp });
            }),
            catchError(err => {
                this.handleError(err, showToastr , isAdmin);
                this.consoleLogData(err);
                return throwError(err);
            })
        );
    }

    postVideo(url, data, showToastr = false , isAdmin = false) {
        this._configHeaders(isAdmin);
        // With Ecryption
        return this.http.post(this._baseApiUrl + url, data , this.httpOptionsforVideo).pipe(
            // Without Ecryption
            // return this.http.post(this._baseApiUrl + url,  data , this.httpOptions).pipe(
            tap((resp: any) => {
                if (resp.status === 'success' && showToastr) {
                    this.showToastr('success', resp.message, '');
                } else if (resp.status === 'failed' && showToastr) {
                    this.showToastr('error', resp.message, '');
                }
                this.consoleLogData({ method: 'POST', url, data, resp });
            }),
            map((resp: any) => {
                let res = {
                    type: resp.type,
                    loaded: resp.loaded,
                    total: resp.total
                };
                if (resp.body) {
                    res = {
                        ...res,
                        ...resp.body
                    };
                }
                // console.log('=====>', res);
                return res;
            }),
            catchError(err => {
                this.handleError(err, showToastr , isAdmin);
                this.consoleLogData(err);
                return throwError(err);
            })
        );
    }

    postFile(url, data, showToastr = false , isAdmin = false) {
        this._configHeaders(isAdmin);
        return this.http.post(this._baseApiUrl + url, data, this.httpOptions).pipe(
            tap((resp: any) => {
                if (resp.status === 'success' && showToastr) {
                    this.showToastr('success', resp.message, '');
                }
                this.consoleLogData({ method: 'POST-FILE', url, data, resp });
            }),
            catchError(err => {
                this.handleError(err, showToastr , isAdmin);
                this.consoleLogData(err);
                return throwError(err);
            })
        );
    }

    handleError(error, showToastr , isAdmin) {
        this.ngxService.stop();
        if (showToastr) {
            this.showToastr('error', error.message, error.status);
        }
        if (error.status === 401) {
            if (isAdmin) {
                this.adminAuthService.removeUser();
                this.router.navigate(['/admin/login']);
            } else {
                this.userAuthService.removeUser();
                this.router.navigate(['/login']);
            }
        } else if (error.status === 500) {
            if (isAdmin) {
                this.router.navigate(['admin/error']);
            } else {
                this.router.navigate(['/server-error']);
            }

        } else if (error.status === 0) {
            if (isAdmin) {
                this.router.navigate(['admin/error']);
            } else {
                this.router.navigate(['/server-error']);
            }

        }
    }

    consoleLogData(data) {
        if (this.logsReqResData) {
            // console.log('------ API LOGS -----', data);
        }
    }

    encrypt(data) {
        return environment.encryption ? AES.encrypt(JSON.stringify(data), environment.apiSecretKey).toString() : data;
    }

    decrypt(data) {
        return environment.encryption ? JSON.parse(AES.decrypt(data, environment.apiSecretKey).toString(utf8)) : data;
    }

    showToastr(type, msg, title) {
        this.toastr[type](msg, title, {
            progressBar: true,
            progressAnimation: 'increasing',
            timeOut: 1500,
            positionClass: 'toast-bottom-center' // There is no interaction button/input
        });
    }
}
