import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoryService } from 'src/app/shared/services/frontend/story.service';

@Component({
    selector: 'app-mobile-story-detail',
    templateUrl: './mobile-story-detail.component.html',
    styleUrls: ['./mobile-story-detail.component.scss']
})
export class MobileStoryDetailComponent {

    story_id = 0;
    storyDetail;

    constructor(private sService: StoryService, private aRoute: ActivatedRoute, private router: Router) {
        this.story_id = this.aRoute.snapshot.params.id;
        // this.sService.getStoryDetail(this.story_id).subscribe(resp => {
        this.sService.getNewsDetail(this.story_id).subscribe(resp => {
            if (resp.status === 'success') {
                this.storyDetail = resp.data;
            } else {
                this.router.navigate(['/not-found']);
            }
        });
    }
}
