import { Injectable } from '@angular/core';
import { CommonHttpClient } from '../common/common-http.service';

@Injectable()
export class StoryService {

    constructor(private chttp: CommonHttpClient) { }

    getStoryDetail(story_id) {
        return this.chttp.post('story-detail', { story_id }, false);
    }

    getAllIssuesTitle() {
        return this.chttp.get('issues-list', false);
    }

    getRecommendedOrganization(data) {
        return this.chttp.post('issue-organization', data, false);
    }

    getUsersIssues(data) {
        return this.chttp.post('user-issues-list', data, false);
    }

    getSupportingIssues(data) {
        return this.chttp.post('supporting-issue', data, false);
    }

    getAllStories(data) {
        return this.chttp.post('all-stories', data, false);
    }

    getNewsFeed(data) {
        return this.chttp.post('get-news-feed-data', data, false);
    }

    getNewsDetail(news_feed_id) {
        return this.chttp.post('get-news-feed-detail', { news_feed_id }, false);
    }

}
