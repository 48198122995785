import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MobileStoryDetailComponent } from './frontend/mobile/mobile-story-detail/mobile-story-detail.component';
import { MobileUsPoliticalFactsDetailComponent } from './frontend/mobile/mobile-uspoliticalfacts-detail/mobile-uspoliticalfacts-detail.component';


const routes: Routes = [
    { path: 'admin', loadChildren: './admin/admin.module#AdminModule' },
    { path: 'mobile/story-detail/:id', component: MobileStoryDetailComponent },
    { path: 'mobile/uspoliticalfacts-detail/:id', component: MobileUsPoliticalFactsDetailComponent },
    { path: '', loadChildren: './frontend/frontend.module#FrontendModule' },
    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
