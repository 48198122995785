import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';

import { AppRoutingModule } from './app-routing.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule, NgxUiLoaderConfig, NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonHttpClient } from './shared/services/common/common-http.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MobileStoryDetailComponent } from './frontend/mobile/mobile-story-detail/mobile-story-detail.component';
import { StoryService } from './shared/services/frontend/story.service';
import { MobileUsPoliticalFactsDetailComponent } from './frontend/mobile/mobile-uspoliticalfacts-detail/mobile-uspoliticalfacts-detail.component';
import { UserService } from './shared/services/frontend/user.service';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    'bgsOpacity': 0.5,
    'bgsPosition': 'bottom-right',
    'bgsSize': 60,
    'bgsType': 'ball-spin-clockwise',
    'blur': 11,
    'fgsPosition': 'center-center',
    'fgsSize': 70,
    'fgsColor': '#ffffff',
    'fgsType': 'square-jelly-box',
    'gap': 24,
    'logoPosition': 'center-center',
    'logoSize': 120,
    'logoUrl': '',
    'masterLoaderId': 'master',
    'overlayBorderRadius': '0',
    'overlayColor': 'rgba(40, 40, 40, 0.8)',
    'pbDirection': 'ltr',
    'pbThickness': 3,
    'hasProgressBar': true,
    'text': '',
    'textPosition': 'center-center',
    'threshold': 500
};

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule ,
        ToastrModule.forRoot({
			maxOpened: 5,
			autoDismiss: true
        }),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgMultiSelectDropDownModule.forRoot()
    ],
    declarations: [AppComponent, MobileStoryDetailComponent, MobileUsPoliticalFactsDetailComponent],
    providers: [ CommonHttpClient , AuthGuard , NgxUiLoaderService,  StoryService, UserService],
    bootstrap: [AppComponent]
})
export class AppModule {}
