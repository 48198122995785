import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { CommonHttpClient } from '../common/common-http.service';
import { UserAuthService } from '../auth/user-auth.service';

@Injectable()
export class UserService {
    constructor(
        private chttp: CommonHttpClient,
        private authService: UserAuthService,
        private router: Router
    ) {
        // if (this.authService.getStoredUser()) {
        //     this.router.navigate(['/dashboard']);
        // }
    }

    login = data => {
        return this.chttp.post('login', data, false).pipe(
            tap((res: any) => {
                if (res.status === 'success') {
                    this.authService.storeUser(res.data);
                }
            })
        );
    }

    signUp = data => {
        return this.chttp.post('register', data, false);
    }

    changePassword(data) {
        return this.chttp.post('changepassword', data, false);
    }

    getVideoSettings() {
        return this.chttp.get('settings', false);
    }

    getBanners() {
        return this.chttp.get('banners', false);
    }

    getCandidates(data) {
        return this.chttp.post('all-candidates', data, false);
    }

    getAllGroupCandidates(data) {
        return this.chttp.post('all-group-candidates', data, false);
    }

    getAllFeaturedCandidates(data) {
        return this.chttp.post('all-featured-candidates', data, false);
    }

    getAllHomeFeaturedCandidates(data) {
        return this.chttp.post('all-home-candidates', data, false);
    }

    getSupportingCandidate(data) {
        return this.chttp.post('supporting-candidate', data, false);
    }

    getProfile() {
        return this.chttp.post('get-profile', {}, false);
    }

    getUserProfile(user_id) {
        return this.chttp.post('user-detail', { user_id }, false);
    }

    editProfile(data) {
        return this.chttp.post('editprofile', data, false);
    }

    forgotPassword(data) {
        return this.chttp.post('forgotpassword', data, false);
    }

    checkEmailExist(data) {
        return this.chttp.post('check-email-exist', data, false);
    }

    verifyEmail(data) {
        return this.chttp.post('verify-email', data, false);
    }

    resetPassword(data) {
        return this.chttp.post('resetpassword', data, false);
    }

    uploadUserAttachments(data) {
        return this.chttp.post('upload-attachment', data, false);
    }

    subscribeNewsletter(data) {
        return this.chttp.post('subscribe/newsletter', data, false);
    }

    logout = () => {
        return this.chttp
            .post('logout', {}, false)
            .pipe(
                tap((res: any) => {
                    if (res.status === 'success') {
                        this.authService.removeUser();
                        this.router.navigate(['/']);
                    }
                })
            )
            .subscribe();
    }

    getFeatureVideos(data) {
        return this.chttp.post('all-videos', data, false);
    }

    getVideoDetail(video_id) {
        return this.chttp.post('video-detail', { video_id }, false);
    }

    getFacts(data) {
        return this.chttp.post('all-facts', data, false);
    }

    getCommentaryFeed(data) {
        return this.chttp.post('get-commentary-feed-data', data, false);
    }

    getNewsFeed(data) {
        return this.chttp.post('get-news-feed-data', data, false);
    }

    getFactDetail(fact_id) {
        return this.chttp.post('fact-detail', { fact_id }, false);
    }

    getCommentaryFeedDetail(commentary_feed_id) {
        return this.chttp.post('get-commentary-feed-detail', { commentary_feed_id }, false);
    }

    getNews() {
        return this.chttp.get('all-news', false);
    }

    getNewsDetail(news_id) {
        return this.chttp.post('news-detail', { news_id }, false);
    }

    getAllGroupIssues(data) {
        return this.chttp.post('all-group-issues', data, false);
    }

    getAllGroupOrganizations(data) {
        return this.chttp.post('all-group-organizations', data, false);
    }

    getCSNData(data) {
        return this.chttp.post('get-csn-data', data, false);
    }
    getCSNDetail(csn_id) {
        return this.chttp.post('get-csn-detail', { csn_id }, false);
    }

    buyMoviePass(data) {
        return this.chttp.post('buy-movie-pass', data, false);
    }

}
