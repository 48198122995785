import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/frontend/user.service';

@Component({
    selector: 'app-mobile-uspoliticalfacts-detail',
    templateUrl: './mobile-uspoliticalfacts-detail.component.html',
    styleUrls: ['./mobile-uspoliticalfacts-detail.component.scss']
})
export class MobileUsPoliticalFactsDetailComponent {

    fact_id = 0;
    factDetail;

    constructor(private uService: UserService, private aRoute: ActivatedRoute, private router: Router) {
        this.fact_id = this.aRoute.snapshot.params.id;

        // this.uService.getFactDetail(this.fact_id).subscribe(resp => {
        this.uService.getCommentaryFeedDetail(this.fact_id).subscribe(resp => {
            if (resp.status === 'success') {
                this.factDetail = resp.data;
            } else {
                this.router.navigate(['/not-found']);
            }
        });
    }
}
