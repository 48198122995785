import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as utf8 from 'crypto-js/enc-utf8';
import * as AES from 'crypto-js/aes';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminAuthService {
    public user;
    public user$ = new BehaviorSubject(undefined);
    private _apiKey = environment.apiSecretKey;

    constructor(private router: Router) {
        this.getStoredUser();
    }

    storeUser(data) {
        const encrypted = AES.encrypt(
            JSON.stringify(data),
            this._apiKey
        ).toString();
        localStorage.setItem('1', encrypted);
        this.user = data;
        this.user$.next(this.user);
    }

    getStoredUser() {
        if (localStorage.getItem('1')) {
            const userdata = localStorage.getItem('1');
            try {
                this.user = JSON.parse(
                    AES.decrypt(userdata, this._apiKey).toString(utf8)
                );
            } catch (err) {
                // Catching error means the data is tempered
                this.user = undefined;
                localStorage.removeItem('1');
            }
        } else {
            this.user = undefined;
            localStorage.removeItem('1');
        }
        this.user$.next(this.user);
        return this.user;
    }

    removeUser() {
        this.user = undefined;
        this.user$.next(this.user);
        localStorage.removeItem('1');
        localStorage.removeItem('selDonationAmount');
        localStorage.removeItem('amount');
    }
}
